import React from 'react';
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import {ConfigProvider} from 'antd';
import {QueryClientProvider} from '@tanstack/react-query';
import {queryClient} from "./Pages/shared/utils/queryClient";

import HomePage from "./Pages/HomePage/HomePage";
import EventsPage from "./Pages/EventsPage/EventsPage";

import customTheme from './theme.overrides';

function App() {
  return (
    <ConfigProvider theme={customTheme}>
      <QueryClientProvider client={queryClient}>
        <Router>
          <Routes>
            <Route path="/events" element={<EventsPage/>}/>
            <Route path="/" element={<HomePage/>}/>
          </Routes>
        </Router>
      </QueryClientProvider>
    </ConfigProvider>
  );
}

export default App;
