import React from 'react';
import {Typography} from "antd";
import {ICellAsText} from "../../../../../shared/constants/interfaces";

const { Text } = Typography;

const CellAsDate: React.FC<ICellAsText> = ({value}) => {
  const date = value.replaceAll('-', '/');
  return (
      <Text>
        {date}
      </Text>
  );
}

export default CellAsDate;
