import React from "react";
import {Space, Typography} from "antd";
const { Title } = Typography;

export interface ErrorMessageProps {
  error: string | unknown;
}

const ErrorOnRequest: React.FC<ErrorMessageProps> = ({error}) => {
  return (
    <Space
      direction='vertical'
      align='center'
      style={{
        padding: 100,
        width: '100%',
        height: 'calc(100vh - 255px',
      }}
    >
      <Title level={3} type='danger'>
        Something went wrong
      </Title>
      <Title level={3}>
        Error: {JSON.stringify(error)}
      </Title>
    </Space>
  )
};

export default ErrorOnRequest;
