import {IStringIndex} from "../../../../../shared/constants/interfaces";

export interface ICellAsChip {
  value: string,
}

export const keyValueMapping: IStringIndex = {
  holiday: 'success',
  competitor: 'warning',
  generic: 'default',
};
