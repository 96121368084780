import {theme} from "antd";

const customTheme = {
  ...theme,
  components: {
    Button: {
      colorPrimary: '#000',
      borderRadius: 4,
      primaryShadow: 'none',
      algorithm: true,
    },
    Input: {
      colorPrimary: '#000',
      borderRadius: 4,
      controlOutlineWidth: 1,
      activeShadow: 'none',
      algorithm: true,
    }
  },
};

export default customTheme;
