import React from "react";
import {Input,Form} from "antd";
import {WrapperFormFieldParams} from "../../ModalWrapper.constatnts";

const {TextArea} = Input;

const TextAreaWrapper: React.FC<WrapperFormFieldParams> = (
  {
    name,
    label,
    required,
  }
) => {
  return (
    <Form.Item
      label={label}
      name={name}
      rules={[
        {
          required: required,
          message: '${label} is required!',
        },
      ]}
    >
      <TextArea/>
    </Form.Item>
  )
};

export default TextAreaWrapper;
