import {useQuery} from "@tanstack/react-query";
import {
  ColumnParams,
  TABLE_DATA_URL,
  TableParams,
  TableResponse, TableRow,
} from "./SummaryTable.constants";
import React, {useMemo} from "react";
import {FormFieldParams} from "../TabContent/TabContent.constants";
import CellAsText from "./components/CellAsText/CellAsText";
import CellAsChip from "./components/CellAsChip/CellAsChip";
import CellAsDate from "./components/CellAsDate/CellAsDate";
import CellAsLongText from "./components/CellAsLongText/CellAsLongText";
import CellAsActions from "./components/CellAsActions/CellAsActions";
import {IStringIndex} from "../../../shared/constants/interfaces";
import {ICellAsActions} from "./components/CellAsActions/CellAsActions.constants";

const formFieldsMapper:IStringIndex = {
  title: {
    render: (value:string) => <CellAsText value={value} />,
    width: 250,
  },
  type: {
    render: (value: string) => <CellAsChip value={value} />,
    width: 180,
  },
  startDate: {
    defaultSortOrder: 'descend',
    render: (value: string) => <CellAsDate value={value} />,
    width: 150,
    title: 'start date'
  },
  endDate: {
    render: (value: string) => <CellAsDate value={value} />,
    width: 150,
    title: 'end date'
  },
  description: {
    render: (value: string) => <CellAsLongText value={value} />,
    width: 'auto',
    config: {
      ellipsis: {
        showTitle: false,
      },
    }
  },
}

export const useTableColumns = (formSchema: FormFieldParams[], onActionClick:ICellAsActions['onActionClick']) => useMemo(() => {
  const tableColumns = formSchema.reduce((result:ColumnParams[],formField: FormFieldParams): any => {
      const fieldName = !Array.isArray(formField.name) ? [formField.name] : formField.name;
      const mappedFieldName = fieldName.map((name: string) => {
        const fieldConfig = formFieldsMapper[name]?.config || {};
        const render = formFieldsMapper[name].render;
        return {
          ...fieldConfig,
          title: (formFieldsMapper[name].title || formField.label).toUpperCase(),
          dataIndex: name,
          sorter: true,
          defaultSortOrder: formFieldsMapper[name]?.defaultSortOrder,
          sortDirections: ['ascend', 'descend', 'ascend'],
          render: render,
        }
      });

      // @ts-ignore
      return [].concat(...result, ...mappedFieldName);
  }, []);

  const actionColumn = {
    name: "actions",
    label: "",
    render: (value:any, record: TableRow) => <CellAsActions value={record} onActionClick={onActionClick}/>,
    width: 100,
  }

  // @ts-ignore
  return [].concat(...tableColumns, actionColumn);

}, [formSchema, onActionClick]);

export const useTableData = (search: string, tableParams: TableParams) => {
  const {pagination, field, order} = tableParams;
  const paginationParams = new URLSearchParams({
    _limit: pagination?.pageSize?.toString() || '10',
    _page: pagination?.current?.toString() || '1',
    _sort: field,
    _order: order === 'descend' ? 'desc' : 'asc'
  });
  let requestURL = `${TABLE_DATA_URL}?${paginationParams}`

  if (search) {
    const searchParams = new URLSearchParams({title_like: search});
    requestURL = `${requestURL}&${searchParams}`
  }

  return useQuery<TableResponse>(
    [{queryKey: 'eventsTable'}, requestURL],
    async () => {
      const response = await fetch(requestURL);
      const headers = response.headers;
      const maxItemsQty = headers.get('X-Total-Count');
      let parsedMaxItemsQty = 0;
      try {
        if (maxItemsQty) {
          parsedMaxItemsQty = parseInt(maxItemsQty, 10);
        }
        const data = await response.json();
        return ({data, maxItemsQty: parsedMaxItemsQty});
      } catch (error) {
        console.log('Error while parsing length items parameter');
        return ({data: [], maxItemsQty: 0});
      }
    },
    {enabled: true}
  );
};
