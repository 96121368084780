import React from 'react';
import {Link} from "react-router-dom";
import {Layout, Typography} from 'antd';
import {contentStyle, footerStyle, headerStyle} from "../shared/styles/layout.styles";

const {Header, Footer, Content} = Layout;

const HomePage: React.FC = () => {
  return (
    <Layout>
      <Header style={headerStyle}>Welcome home page</Header>
      <Content style={contentStyle}>
        <Typography>I hope you will enjoy</Typography>
        <Typography>Lets check our&nbsp;
          <Link to="/events">Events</Link>
        </Typography>
      </Content>
      <Footer style={footerStyle}>Footer</Footer>
    </Layout>
  );
}

export default HomePage;
