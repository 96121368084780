import React from "react";
import {Skeleton} from "antd";

export interface SkeletonProps {
  height?: string | number;
}

const Loader: React.FC<SkeletonProps> = ({height = 'calc(100vh - 254px'}) => {
  return (
    <Skeleton
      active
      style={{
        height: height,
        padding: 100,
      }}
    />
  )
};

export default Loader;
