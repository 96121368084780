import React from "react";
import {Form, Select} from "antd";
import {WrapperFormFieldParams} from "../../ModalWrapper.constatnts";

const SelectWrapper: React.FC<WrapperFormFieldParams> = (
  {
    name,
    label,
    required,
    config
  }
) => {
  return (
    <Form.Item
      label={label}
      name={name}
      rules={[
        {
          required: required,
          message: '${label} is required!',
        },
      ]}
    >
      <Select
        options={config}
      />
    </Form.Item>
  )
};

export default SelectWrapper;
