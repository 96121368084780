import type {TablePaginationConfig} from 'antd/es/table';
import {FormFieldParams} from "../TabContent/TabContent.constants";
import React, {Dispatch, SetStateAction} from "react";
import {Dayjs} from "dayjs";
import {IStringIndex} from "../../../shared/constants/interfaces";

export interface SummaryTableProps {
  search: string;
  formSchema: FormFieldParams[];
  onActionClick: Dispatch<SetStateAction<{}>>
}

export interface TableRow {
  id: number;
  title: string;
  type: string;
  startDate: Dayjs;
  endDate: Dayjs;
  description: string;
};

export interface TableParams extends IStringIndex{
  pagination: TablePaginationConfig;
}

export interface TableResponse {
  data: TableRow[];
  maxItemsQty: number,
}

export const defaultPaginationParams = {
  current: 1,
  pageSize: 10,
};

export const defaultTableParams: TableParams = {
  pagination: defaultPaginationParams,
  field: 'startDate',
  order: 'descend',
}

export const defaultTableResponse: TableResponse = {
  data: [],
  maxItemsQty: 0,
};

export interface ColumnParams {
  title: string;
  dataIndex: string;
  render: () => React.FC;
  width: number;
  defaultSortOrder: string;
}

export const TABLE_DATA_URL: String = `${process.env.REACT_APP_API_EVENT_URL}/events`;
