import React from 'react';
import {TABLE_DATA_URL} from "../../SummaryTable.constants";
import {Dropdown, Typography} from "antd";
import type { MenuProps } from 'antd';

import {ACTION_ITEMS, IActionItem} from "../../../TabContent/TabContent.constants";
import {ICellAsActions} from "./CellAsActions.constants";

const EDIT_DELETE_ACTIONS: IActionItem[] = ACTION_ITEMS.slice(1);

const CellAsActions: React.FC<ICellAsActions> = ({value, onActionClick}) => {
  const onClick: MenuProps['onClick'] = ({key}) => {
    const actionIndex = parseInt(key, 10);
    const actionItem: IActionItem = ACTION_ITEMS[actionIndex];
    const requestURL = `${TABLE_DATA_URL}/${value.id}`
    onActionClick(actionItem, value, requestURL);
  };

  return (
    <Dropdown menu={{ items: EDIT_DELETE_ACTIONS, onClick }}>
        <Typography style={{
          textAlign: 'center',
        }}>...</Typography>
    </Dropdown>
  );
}

export default CellAsActions;
