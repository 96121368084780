import React, {useState} from 'react';
import {Table} from "antd";
import {useTableColumns, useTableData} from "./SummaryTable.service";
import {ColumnProps, TablePaginationConfig} from "antd/es/table";
import {SorterResult} from "antd/es/table/interface";

import {
  defaultTableParams,
  defaultTableResponse,
  SummaryTableProps,
  TableParams,
  TableRow
} from "./SummaryTable.constants";

const SummaryTable: React.FC<SummaryTableProps> = ({search, formSchema, onActionClick}) => {
  const [tableParams, setTableParams] = useState<TableParams>({
    ...defaultTableParams
  });
  const columns: ColumnProps<TableRow>[] = useTableColumns(formSchema, onActionClick);
  const {data = defaultTableResponse, isLoading, error} = useTableData(search, tableParams);

  const onChange = (
    pagination: TablePaginationConfig,
    filters: any,
    sorter: SorterResult<TableRow> | SorterResult<TableRow>,
    extra: any) => {
    const {field, order} = sorter;
    setTableParams({
      pagination,
      field,
      order,
    });
  };


  return (
    <Table
      id="events"
      data-testid="events"
      columns={columns}
      rowKey={(record) => record.id}
      dataSource={data?.data}
      pagination={{
        ...tableParams.pagination,
        total: data.maxItemsQty,
      }}
      loading={isLoading}
  // @ts-ignore
      onChange={onChange}
      scroll={{ y: 'calc(100vh - 422px' }}
    />
  );
}

export default SummaryTable;
