import React from 'react';
import {Layout, Typography} from 'antd';
import {Link} from "react-router-dom";
import TabSelector from "./components/TabSelector/TabSelector";
import {contentStyle, footerStyle, headerStyle} from "../shared/styles/layout.styles";

const {Header, Footer, Content} = Layout;

const EventsPage: React.FC = () => {
  return (
    <Layout>
      <Header style={headerStyle}>Test task</Header>
      <Content style={contentStyle}>
        <TabSelector/>
      </Content>
      <Footer style={footerStyle}>
        <Typography>
          back to&nbsp;
          <Link to="/">Home</Link>
        </Typography>
      </Footer>
    </Layout>
  );
}

export default EventsPage;
