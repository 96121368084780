import React from "react";

export const headerStyle: React.CSSProperties = {
  textAlign: 'center',
  color: '#fff',
  height: 64,
  paddingInline: 50,
  lineHeight: '64px',
  backgroundColor: '#7dbcea',
};

export const footerStyle: React.CSSProperties = {
  textAlign: 'center',
  color: '#fff',
  height: 64,
  backgroundColor: '#7dbcea',
};

export const contentStyle: React.CSSProperties = {
  padding: 32,
  // height: `calc(100vh - ${headerStyle.height}px - ${footerStyle.height}px)`,
  color: '#fff',
  backgroundColor: '#fff',
};
