import React from 'react';
import {Tooltip, Typography} from "antd";
import {ICellAsText} from "../../../../../shared/constants/interfaces";

const {Text} = Typography;

const CellAsLongText: React.FC<ICellAsText> = ({value}) => {
  return (
      <Tooltip placement="topLeft" title={value}>
        <Text>
          {value}
        </Text>
      </Tooltip>
  );
}

export default CellAsLongText;
