import {useMutation, useQuery} from "@tanstack/react-query";
import {EVENT_DATE_FORMAT, FORM_SCHEMA_URL, FormFieldParams} from "./TabContent.constants";
import {useMemo} from "react";
import dayjs from "dayjs";
import {queryClient} from "../../../shared/utils/queryClient";
import {IStringIndex} from "../../../shared/constants/interfaces";
import {RANGE_PICKER_FORM_FIELD} from "../SummaryTable/components/CellAsDate/CellAsDate.constants";

export const useFormSchema = () => {
  let requestURL = FORM_SCHEMA_URL

  return useQuery<FormFieldParams[]>(
    [{queryKey: 'eventsFormSchema'}],
    async () => {
      const response = await fetch(requestURL);
      const data = await response.json();
      return data[0].schema
    },
    {enabled: true}
  );
};

export const useFinalizeAction = () => {
  return useMutation(
  // @ts-ignore
    ({data, actionParams}) => {
      const requestConfig = {
        method: actionParams.method,
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      };

      return fetch(actionParams.requestURL, requestConfig)
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([{queryKey: 'eventsTable'}])
      },
    }
  )
}

export const transformData = (data: IStringIndex) => {
  const formDataKeys = Object.keys(data);
  return formDataKeys.reduce((result: any, formFieldKey: string) => {
    let value = {[formFieldKey]: data[formFieldKey]};
    const isDateRangeValue = formFieldKey === RANGE_PICKER_FORM_FIELD;
    if(isDateRangeValue) {
      value = {
        startDate: dayjs(data[formFieldKey][0]).format(EVENT_DATE_FORMAT),
        endDate: dayjs(data[formFieldKey][1]).format(EVENT_DATE_FORMAT),
      }
    }

    return {
      ...result,
      ...value,
    }
  }, {});
}


export const useGetDefaultFormData = (formSchema: FormFieldParams[] | undefined, modalData: IStringIndex) => useMemo(() => {
  if (!formSchema) {
    return {};
  }

  return formSchema.reduce((data: {}, formField: FormFieldParams) => {
    const isDateRange = formField.component === RANGE_PICKER_FORM_FIELD;
    const name = isDateRange ? RANGE_PICKER_FORM_FIELD : formField.name;

    return {
      ...data,
      [name]: isDateRange ? [dayjs(modalData?.startDate), dayjs(modalData?.endDate)] : modalData?.[formField.name]
    }
  }, {})

}, [formSchema, modalData])
