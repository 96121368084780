import React from 'react';
import {Typography} from "antd";
import {ICellAsText} from "../../../../../shared/constants/interfaces";

const { Text } = Typography;

const CellAsText: React.FC<ICellAsText> = ({value}) => {
  return (
    <div style={{
      maxWidth: 300,
      overflow:'hidden',
    }}>
      <Text>
        {value}
      </Text>
    </div>
  );
}

export default CellAsText;
