import React, {useEffect, useState} from "react";
import {Form, Modal} from "antd";
import {ModalWrapperParams, VALIDATE_MESSAGE} from "./ModalWrapper.constatnts";
import {useGetFormFields} from "./ModalWrapper.service";
import DeleteConfirm from "./components/DeleteConfirm/DeleteConfirm";

// @ts-ignore
const ModalWrapper: React.FC<ModalWrapperParams> = (
  {
    modalUIParams,
    name,
    onSubmit,
    onCancel,
    formSchema,
    data,
  }
) => {
  const [form] = Form.useForm();
  const formFields = useGetFormFields(formSchema);

  const onSubmitHandler = async() => {
    try{
      const validValues = await form.validateFields();
      await onSubmit(validValues);
    } catch (err) {
      console.log(err);
    }
  }

  const onCancelHandler = () => {
    form.resetFields();
    onCancel();
  }

  useEffect(() => {
    form.setFieldsValue(data);
  }, [data, form])

  if(modalUIParams.method === 'DELETE') {
    return (
      <DeleteConfirm
        open={modalUIParams.isOpen}
        formData={data}
        onSubmitHandler={onSubmitHandler}
        onCancelHandler={onCancelHandler}
      />
    )
  }


  return (
    <Modal
      forceRender
      title={modalUIParams.label}
      centered
      open={modalUIParams.isOpen}
      onOk={onSubmitHandler}
      onCancel={onCancelHandler}
      okText={modalUIParams.label}
    >
      <Form
        form={form}
        layout='vertical'
        name={name}
        initialValues={data}
        validateMessages={VALIDATE_MESSAGE}
      >
        {formFields}
      </Form>
    </Modal>
  )
};

export default ModalWrapper;
