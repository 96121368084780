import React from 'react';
import {Tabs} from 'antd';
import {items} from "./TabSelector.constants";


const TabSelector: React.FC = () => (
  <Tabs
    defaultActiveKey="1"
    items={items}
  />
);

export default TabSelector;
