import React from "react";
import {Form, DatePicker} from "antd";
import {WrapperFormFieldParams} from "../../ModalWrapper.constatnts";

const {RangePicker} = DatePicker;

const RangePickerWrapper: React.FC<WrapperFormFieldParams> = (
  {
    name,
    label,
    required,
  }
) => {
  return (
    <Form.Item
      label={label}
      name={name}
      rules={[
        {
          required: required,
          message: '${label} is required!',
        },
      ]}
    >
      <RangePicker
        format="YYYY-MM-DD"
        style={{width: '100%'}}
      />
    </Form.Item>
  )
};

export default RangePickerWrapper;
