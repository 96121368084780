import {ACTION_ITEMS, FormFieldParams, IOption} from "../../../EventsPage/components/TabContent/TabContent.constants";
import {IStringIndex} from "../../constants/interfaces";

export interface IModalUIParams {
  isOpen: boolean;
  label:string;
  method: string;
};

export const DEFAULT_MODAL_UI_PARAMS: IModalUIParams = {
  isOpen: false,
  label: ACTION_ITEMS[0].label,
  method: '',
}

export interface ModalWrapperParams {
  modalUIParams: IModalUIParams,
  name: string;
  onSubmit(data:any): void;
  onCancel(): void;
  formSchema: FormFieldParams[];
  data: IStringIndex  | undefined;
}

export const VALIDATE_MESSAGE = {
  required: '${label} is required!',
};

export interface WrapperFormFieldParams {
  name: string;
  defaultValue: string | number | any;
  label: string;
  required: boolean;
  config?: IOption[];
};

export interface DeleteParams {
  formData: IStringIndex;
  onSubmit(data:any): void;
  onCancel(): void;
};
