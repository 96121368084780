import type {TabsProps} from 'antd';
import TabContent from "../TabContent/TabContent";

export const DEFAULT_SELECTED_TAB: string = 'events'

export const items: TabsProps['items'] = [
  {
    key: DEFAULT_SELECTED_TAB,
    label: 'Events Summary Table',
    children: <TabContent/>,
  },
  {
    key: 'futureTab',
    label: 'To be implemented',
    disabled: true,
  },
];
