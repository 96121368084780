import {TableRow} from "../SummaryTable/SummaryTable.constants";
import dayjs from "dayjs";
import {MenuItemType} from "antd/es/menu/hooks/useItems";

export const FORM_SCHEMA_KEY: string = 'events';
export const FORM_SCHEMA_URL: string = `${process.env.REACT_APP_API_EVENT_URL}/forms?name_like=${FORM_SCHEMA_KEY}`;
export const EVENT_DATE_FORMAT: string = 'YYYY-MM-DD';

export interface IActionItem extends MenuItemType{
  method: string;
  label: string,
}

export const ACTION_ITEMS: IActionItem[] = [
  {
    key: '0',
    label: 'Create Event',
    method: 'POST'
  },
  {
    key: '1',
    label: 'Edit Event',
    method: 'PUT'
  },
  {
    key: '2',
    label: 'Delete Event',
    method: 'DELETE',
  },
]

export const DEFAULT_SELECTED_ROW: TableRow = {
  id: -1,
  title: '',
  type: '',
  startDate: dayjs(),
  endDate: dayjs(),
  description: '',
};

export interface IOption {
  label: string,
  value: string,
}

export interface FormFieldParams {
  name: string;
  label: string;
  component: string;
  required?: boolean;
  options?: IOption[];
}

export interface IFormDataParams {
  title: string;
  type: string;
  range_picker: [string, string];
  description: string;
}

