import React from 'react';
import { Tag } from 'antd';
import {ICellAsChip, keyValueMapping} from "./CellAsChip.constants";

const CellAsChip: React.FC<ICellAsChip> = ({value}) => {
  const backgroundColor = keyValueMapping[value];
  return (
      <Tag color={backgroundColor}>
        {value}
      </Tag>
  );
}

export default CellAsChip;
