import {useMemo} from "react";
import {FormFieldParams} from "../../../EventsPage/components/TabContent/TabContent.constants";
import InputWrapper from "./components/InputWrapper/InputWrapper";
import SelectWrapper from "./components/SelectWrapper/SelectWrapper";
import RangePickerWrapper from "./components/RangePickerWrapper/RangePickerWrapper";
import {randomKey} from "../../utils/getRandomIndex";
import TextAreaWrapper from "./components/TextAreaWrapper/TextAreaWrapper";
import {IStringIndex} from "../../constants/interfaces";
import {RANGE_PICKER_FORM_FIELD} from "../../../EventsPage/components/SummaryTable/components/CellAsDate/CellAsDate.constants";

const formFieldsMapper: IStringIndex = {
  text: InputWrapper,
  select: SelectWrapper,
  [RANGE_PICKER_FORM_FIELD]: RangePickerWrapper,
  textarea: TextAreaWrapper,
}


export const useGetFormFields = (formSchema: FormFieldParams[]) => useMemo(() => {
  return formSchema
    .map((formField: FormFieldParams, index: number)  => {
      const FormFieldComponent = formFieldsMapper[formField.component];
      const uniqueKey = randomKey();
      const name = formField.component === RANGE_PICKER_FORM_FIELD ? RANGE_PICKER_FORM_FIELD : formField.name;
      return (
        <FormFieldComponent
          config={formField.options}
          name={name}
          label={formField.label}
          required={formField.required}
          key={`${formField.name}-${uniqueKey}`}
        />
      )
    })
}, [formSchema]);
