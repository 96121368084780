import React from "react";
import {Form, Input} from "antd";
import {WrapperFormFieldParams} from "../../ModalWrapper.constatnts";

const InputWrapper: React.FC<WrapperFormFieldParams> = (
  {
    name,
    label,
    required,
  }
) => {
  return (
    <Form.Item
      label={label}
      name={name}
      rules={[
        {
          required: required,
          message: '${label} is required!',
        },
      ]}
    >
      <Input/>
    </Form.Item>
  )
};

export default InputWrapper;
