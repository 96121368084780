import React from "react";
import {Modal} from "antd";


// @ts-ignore
const DeleteConfirm = ({open, formData, onSubmitHandler, onCancelHandler}) => {
  return (
    <Modal
      title="Delete event?"
      open={open}
      onOk={async () => {
        await onSubmitHandler(formData);
      }}
      onCancel={() => {
        onCancelHandler();
      }}
    >
      Are you sure to delete event {formData.title} ?
    </Modal>
  )
}

export default DeleteConfirm;