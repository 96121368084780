import React, {useCallback, useState} from 'react';
import SummaryTable from "../SummaryTable/SummaryTable";
import {Input, Button, Row, Col, message} from 'antd';
import {SearchProps} from "antd/lib/input";
import ErrorOnRequest from "../../../shared/components/ErrorOnRequest/ErrorOnRequest";
import Loader from "../../../shared/components/Loader/Loader";
import ModalWrapper from "../../../shared/components/ModalWrapper/ModalWrapper";

import {TABLE_DATA_URL, TableRow} from "../SummaryTable/SummaryTable.constants";

import {transformData, useFinalizeAction, useFormSchema, useGetDefaultFormData} from "./TabContent.service";

import {ACTION_ITEMS, DEFAULT_SELECTED_ROW, IActionItem} from "./TabContent.constants";
import {DEFAULT_MODAL_UI_PARAMS} from "../../../shared/components/ModalWrapper/ModalWrapper.constatnts";

import {rowStyle} from "./TabContent.styles";

const {Search} = Input;

const TabContent: React.FC = () => {
  const [search, setSearch] = useState('');
  const [modalUIParams, setModalUIParams] = useState(DEFAULT_MODAL_UI_PARAMS);
  const [actionParams, setActionParams] = useState({});

  const {data: formSchema, isLoading, error, isError} = useFormSchema();
  const [selectedRow, setSelectedRow] = useState(DEFAULT_SELECTED_ROW);
  const modalData = useGetDefaultFormData(formSchema, selectedRow);

  const {mutate: finalizeAction, error:errorOnUserAction, isError: isErrorOnUserAction } = useFinalizeAction();

  const onSearch: SearchProps['onSearch'] = (value, _e, info) => {
    setSearch(value);
  }

  const onCancel = useCallback(() => {
    setModalUIParams(DEFAULT_MODAL_UI_PARAMS);
    setSelectedRow(DEFAULT_SELECTED_ROW);
    setActionParams({});
  }, []);

  const onSubmit = useCallback((data: any) => {
    const transformedData = transformData(data);
    // @ts-ignore
    finalizeAction({data: transformedData, actionParams});
    message.success('Data updated successfully');
    onCancel();
  }, [actionParams, finalizeAction, onCancel]);

  const onEventCreate = useCallback(() => {
    const {method, label} = ACTION_ITEMS[0];
    setSelectedRow(DEFAULT_SELECTED_ROW);
    setActionParams({method, requestURL: TABLE_DATA_URL});
    setModalUIParams(() => ({
      isOpen: true,
      label: label,
      method: method,
    }));
  }, []);

  const onActionClick: any = useCallback((actionParams: IActionItem, selectedRow: TableRow, requestURL: string) => {
    setSelectedRow(selectedRow);
    setActionParams({...actionParams, requestURL});
    setModalUIParams(() => ({
      isOpen: true,
      label: actionParams.label,
      method: actionParams.method,
    }));

  }, [])


  if (isLoading) {
    return <Loader/>
  }

  if (isError || isErrorOnUserAction) {
    return <ErrorOnRequest error={error || errorOnUserAction}/>
  }

  return (
    <>
      <ModalWrapper
        name='event'
        modalUIParams={modalUIParams}
        onSubmit={onSubmit}
        onCancel={onCancel}
        formSchema={formSchema}
        data={modalData}
      />
      <Row style={rowStyle}>
        <Col>
          <Search
            allowClear
            enterButton
            placeholder="Search events"
            onSearch={onSearch}
          />
        </Col>
        <Col flex="auto"></Col>
        <Col>
          <Button
            type="primary"
            onClick={onEventCreate}
          >
            Create event
          </Button>
        </Col>
      </Row>
      <Row>
        <SummaryTable
          search={search}
          formSchema={formSchema}
          onActionClick={onActionClick}
        />
      </Row>
    </>
  );
}

export default TabContent;
